<template>
  <validation-observer ref="reportForm">
    <b-form>
      <b-modal id="bv-modal-add-course" centered hide-footer>
        <!-- Start Modal Header -->
        <template #modal-header>
          <div
            class="mb-2 d-flex justify-content-between w-100 align-items-center"
          >
            <b-card-text class="mb-0">
              {{
                LocalEditCheckValue
                  ? $t("g.courses/add_course_model/title_edit")
                  : $t("g.add-course")
              }}
            </b-card-text>
            <img
              :src="exitIcon"
              alt="Hide add course"
              @click="$bvModal.hide('bv-modal-add-course')"
            />
          </div>
          <span class="sub-title">
            {{ $t("g.please_fill_in_the_required_data") }}
          </span>
        </template>
        <!-- End Modal Header -->

        <!-- Start Course Name Input -->
        <b-form-group label-for="name" class="mb-2">
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              :placeholder="$t('g.course_name')"
              v-model="form_data.courseName"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End Course Name Input -->

        <!-- Start Course Type Select -->
        <div class="course-type-container">
          <b-form-group label-for="course type" class="mb-2">
            <validation-provider
              #default="{ errors }"
              name="course type"
              rules="required"
            >
              <b-form-select
                :options="typeOptions"
                v-model="form_data.selectedType"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
              <svg
                class="select-arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15.8346 7.5L10.0013 13.3333L4.16797 7.5"
                  stroke="#5E5E5E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </validation-provider>
          </b-form-group>
        </div>
        <!-- End Course Type Select -->

        <!-- Start Edy Year Select -->
        <div class="edy-year-container">
          <b-form-group
            label-for="educational year"
            class="mb-2"
            v-if="form_data.selectedType === 0"
          >
            <validation-provider
              #default="{ errors }"
              name="educational year"
              :rules="form_data.selectedType === 0 ? 'required' : ''"
            >
              <b-form-select
                :options="edyYearOptions"
                v-model="form_data.selectedEdyYear"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
              <svg
                class="select-arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15.8346 7.5L10.0013 13.3333L4.16797 7.5"
                  stroke="#5E5E5E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </validation-provider>
          </b-form-group>
        </div>
        <!-- End Edy Year Select -->

        <!-- Start Course Price Input -->
        <b-form-group label-for="price" class="mb-2">
          <validation-provider
            #default="{ errors }"
            name="price"
            rules="required"
          >
            <b-form-input
              type="number"
              :placeholder="$t('g.price')"
              v-model="form_data.price"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End Course Price Input -->

        <!-- Start Course Sale Input -->
        <b-form-group label-for="sale" class="mb-2">
          <validation-provider #default="{ errors }" name="sale">
            <b-form-input
              type="number"
              :placeholder="$t('g.sale')"
              v-model="form_data.sale"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End Course Sale Input -->

        <!-- Start Date Select -->
        <b-row>
          <b-col cols="12" sm="6">
            <b-form-group label-for="start date">
              <validation-provider
                #default="{ errors }"
                name="start date"
                rules="required"
              >
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :placeholder="$t('g.courses/addcourseModel/form/start_date')"
                  v-model="form_data.start_date"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label-for="end date">
              <validation-provider
                #default="{ errors }"
                name="end date"
                rules="required"
              >
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :placeholder="$t('g.courses/addcourseModel/form/end_date')"
                  v-model="form_data.end_date"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="dateError" cols="12" class="date-error">
            <p>{{ $t("g.alert.the_start_date_must_be_before_end_date") }}</p>
          </b-col>
        </b-row>
        <!-- End Date Select -->

        <!-- Start description textarea -->
        <b-form-group label-for="description">
          <validation-provider
            #default="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-textarea
              :placeholder="$t('g.courses/addcourseModel/form/description')"
              v-model="form_data.description"
              rows="5"
              max-rows="10"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End description textarea -->

        <!-- Start Image Input -->
        <b-form-group label-for="course image" class="mb-4">
          <validation-provider #default="{ errors }" name="course image">
            <div
              class="image-input-area"
              v-if="
                !form_data.selectedimage || form_data.selectedimage.length == 0
              "
            >
              <b-form-file
                class="image-input"
                ref="courseImage"
                v-model="form_data.selectedimage"
                @change="handleFileChange"
                accept="image/*"
              />
              <div class="image-icon">
                <img :src="imageInputIcon" alt="Add course image" />
                <div class="icon-circle">
                  <img :src="plusIcon" alt="plus icon" />
                </div>
              </div>
              <p class="image-text mb-0">{{ $t("g.upload_photo") }}</p>
            </div>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <b-row
          v-if="form_data.selectedimage && form_data.selectedimage.length != 0"
          class="mx-1 mt-n2 mb-3"
        >
          <p class="title">
            {{ $t("g.add_course/form//media_section") }}
          </p>
          <div class="path-preview w-100">
            <img
              class="sm-img-preview"
              :src="imageIcon"
              alt="Instructions-Type"
            />
            <span class="name">
              {{
                form_data.selectedimage.name ||
                form_data.selectedimage[0].description
              }}</span
            >
            <label for="fileInput" class="float-right">
              <span
                ><img :src="editIcon" alt="pen-tool" @click="editImage"
              /></span>
            </label>
          </div>
        </b-row>
        <!-- End Image Input -->

        <!-- start Teacher Control -->
        <b-row class="justify-content-between align-items-center mt-n2 w-100">
          <p class="title ml-2 mb-0">
            {{ $t("g.add_course/form/teacher_section") }}
          </p>
          <button
            class="float-icon"
            v-if="form_data.teachers !== null"
            style="all: unset; cursor: pointer"
            @click="$bvModal.show('bv-modal-teacher-control')"
          >
            <feather-icon
              v-if="form_data.teachers.length === 0"
              icon="PlusIcon"
              size="19"
              class="text-body align-center float-right"
              style="font-weight: bold"
            ></feather-icon>
            <img
              v-else-if="form_data.teachers.length > 0"
              :src="editIcon"
              alt="pen-tool"
            />
          </button>
          <b-row class="w-100 ml-1 mt-2" v-if="form_data.teachers != null">
            <b-col cols="10">
              <span
                class="link mr-1"
                v-for="item in form_data.teachers"
                :key="item.id"
              >
                {{ item.user.first_name }} {{ item.user.last_name }}
              </span>
            </b-col>
          </b-row>
        </b-row>
        <!-- end Teacher Control -->

        <!-- Start Form Actions Buttons -->
        <b-row align-h="end" class="report-footer m-0 mt-5">
          <b-button
            variant="outline-primary"
            @click="$bvModal.hide('bv-modal-add-course')"
            >{{ $t("g.cancel") }}
          </b-button>
          <b-button
            :disabled="is_loading"
            variant="primary"
            type="submit"
            @click.prevent="validation"
            >{{
              LocalEditCheckValue
                ? $t("g.courses/addcourseModel/form/buttons/edit")
                : $t("g.send_request")
            }}
          </b-button>
        </b-row>

        <!-- Overlay component -->
        <overlay-component :isLoading="is_loading" />
        <!-- Overlay component -->
        <TeachersControl
          :teachers="form_data.teachers"
          @updateTeacher="updateTeacherFn"
        />
      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TeachersControl from "@/components/classrooms/course/AddCourseModal/teacherControl";
import {
  BModal,
  BButton,
  BCol,
  BForm,
  BFormSelect,
  BFormTextarea,
  BRow,
  BFormFile,
  BFormGroup,
  BCardText,
  BFormInput,
  BFormDatepicker,
  BBadge,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  props: ["page_data", "is_Edit"],
  components: {
    BModal,
    BButton,
    BForm,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormFile,
    OverlayComponent,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
    FeatherIcon,
    BBadge,
    TeachersControl,
  },
  data() {
    return {
      form_data: {
        courseName: null,
        teachers: [],
        selectedimage: [],
        end_date: null,
        start_date: null,
        description: null,
        selectedEdyYear: null,
        selectedType: null,
        price: null,
        sale: null,
      },
      required: required,
      is_loading: true,
      dateError: false,
      current_id: null,
      LocalEditCheckValue: null,
      typeOptions: [
        {
          selected: true,
          value: null,
          disabled: true,
          text: this.$t("g.course_type"),
        },
        { value: 0, text: this.$t("g.academic_course") },
        { value: 1, text: this.$t("g.summer_course") },
      ],
      edyYearOptions: [
        {
          selected: true,
          value: null,
          disabled: true,
          text: this.$t("g.educational_year"),
        },
      ],
      exitIcon: require("@/assets/dev/exit_icon.svg"),
      plusIcon: require("@/assets/dev/image_input_icon2.svg"),
      imageInputIcon: require("@/assets/dev/image_input_icon.svg"),
      editIcon: require("@/assets/images/icons/pencil.svg"),
      imageIcon: require("@/assets/images/icons/photo.png"),
    };
  },
  beforeMount() {
    this.getEduYear();
    this.current_id = this.page_data.id;
    this.getCourseDetials(this.page_data.id);
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getEduYear() {
      this.is_loading = true;
      try {
        const res = await this.$http.get("/admin/edu-years");
        for (const eduYear of res.data.data) {
          this.edyYearOptions.push({
            value: eduYear.id,
            text: eduYear.name,
          });
        }
        this.is_loading = false;
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    async createCourse() {
      try {
        this.is_loading = true;
        const formData = new FormData();
        formData.append("name", this.form_data.courseName);
        formData.append("is_summer", this.form_data.selectedType);
        this.form_data.selectedType === 0 &&
          formData.append("edu_year", this.form_data.selectedEdyYear);
        formData.append("start_date", this.form_data.start_date);
        formData.append("end_date", this.form_data.end_date);
        formData.append("price", this.form_data.price);
        this.form_data.sale && formData.append("sale", this.form_data.sale);
        formData.append("description", this.form_data.description);
        formData.append("image[0]", this.form_data.selectedimage);
        formData.append("color", "white");
        formData.append("is_enrollement_open", 1);
        formData.append("is_approved", 1);
        this.form_data.teachers.map((item, index) => {
          formData.append(`teachers[${index}][teacher_id]`, item.id);
          formData.append(`teachers[${index}][is_owner]`, 0);
        });
        const res = await this.$http.post("/admin/courses", formData);
        if (res.status === 200 || res.status === 201) {
          this.makeToast("success", res.data.message, res.data.message);
          this.$bvModal.hide("bv-modal-add-course");
          this.$emit("createCourse");
        }
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },

    async editCourse() {
      try {
        this.is_loading = true;
        const formData = new FormData();
        formData.append("name", this.form_data.courseName);
        formData.append("is_summer", this.form_data.selectedType);
        this.seletedType === 0 &&
          formData.append("edu_year", this.form_data.selectedEdyYear);
        formData.append("start_date", this.form_data.start_date);
        formData.append("end_date", this.form_data.end_date);
        formData.append("price", this.form_data.price);
        this.form_data.sale && formData.append("sale", this.form_data.sale);
        formData.append("description", this.form_data.description);
        formData.append("image[0]", this.form_data.selectedimage);
        formData.append("color", "white");
        formData.append("is_enrollement_open", 1);
        for (let item in this.form_data.teachers) {
          formData.append(
            `teachers[${item}]`,
            this.form_data.teachers[item].id
          );
        }
        const res = await this.$http.post(
          `/admin/courses/${this.current_id}?_method=put`,
          formData
        );
        if (res.status === 200 || res.status === 201) {
          this.makeToast("success", res.data.message, res.data.message);
          this.$bvModal.hide("bv-modal-add-course");
          this.$emit("createCourse");
        }
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },

    editImage() {
      this.form_data.selectedimage = null;
      this.$refs.courseImage?.$el.querySelector('input[type="file"]').click();
    },
    handleFileChange(event) {
      this.form_data.selectedimage = event.target.files[0];
    },
    validation() {
      const dateState = this.compareShortDates();
      this.$refs.reportForm
        .validate()
        .then((success) =>
          success && dateState && this.LocalEditCheckValue
            ? this.editCourse()
            : this.createCourse()
        );
    },
    // compare start and end date
    compareShortDates() {
      if (this.form_data.start_date && this.form_data.end_date) {
        const start_date = new Date(this.form_data.start_date);
        const end_date = new Date(this.form_data.end_date);
        if (start_date > end_date) {
          this.dateError = true;
          return 0;
        } else if (start_date < end_date) {
          this.dateError = false;
          return 1;
        }
      }
    },
    updateTeacherFn(newTeachers) {
      this.form_data.teachers = newTeachers;
      this.$bvModal.hide("bv-modal-teacher-control");
    },
    getCourseDetials(current_id) {
      this.is_loading = true;
      if (current_id) {
        this.$http
          .get(`admin/courses/${current_id}`)
          .then((res) => {
            this.form_data.teachers = res.data.data.teachers;
            this.form_data.sale = res.data.data.sale;
            this.form_data.price = res.data.data.price;
            this.form_data.end_date = res.data.data.end_date;
            this.form_data.start_date = res.data.data.start_date;
            this.form_data.description = res.data.data.description;
            this.form_data.courseName = res.data.data.name;
            this.form_data.selectedimage = res.data.data.image;
            this.form_data.selectedType = res.data.data.is_summer ? 1 : 0;
          })
          .catch((err) => {
            this.$helper.handleError(err);
          })
          .finally(() => {
            this.is_loading = false;
          });
      }
    },
  },
  watch: {
    "form_data.teachers": {
      deep: true,
      handler(newValue) {},
    },
    "form_data.start_data": {
      deep: true,
      handler() {
        this.dateError = false;
      },
    },
    "form_data.end_date": {
      deep: true,
      handler() {
        this.dateError = false;
      },
    },

    is_Edit(newVal) {
      if (newVal) {
        this.form_data.selectedEdyYear = this.page_data.edu_year;
        this.LocalEditCheckValue = newVal;
        this.current_id = this.page_data.id;
        this.getCourseDetials();
        this.form_data.selectedType = this.page_data.is_summer ? 1 : 0;
      } else {
        this.form_data.selectedEdyYear = this.page_data.edu_year;
        this.LocalEditCheckValue = false;
        for (let item in this.form_data) {
          if (item == "selectedEdyYear") {
            this.form_data[item] = this.page_data.edu_year;
          } else if (item === "teachers") {
            this.form_data[item] = [];
          } else {
            this.form_data[item] = null;
          }
        }
        this.is_loading = false;
        this.form_data.selectedType = this.page_data.is_summer ? 1 : 0;
        this.page_data.is_summer
          ? (this.typeOptions[1].disabled = true)
          : (this.typeOptions[2].disabled = true);
      }
    },
    "page_data.id"(newVal) {
      this.current_id = newVal;
    },
    "page_data.edu_year"(newVal) {
      if (newVal) {
        this.form_data.selectedEdyYear = newVal;
      }
    },
    current_id(newVal) {
      this.LocalEditCheckValue ? this.getCourseDetials(newVal) : null;
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>

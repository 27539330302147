<template>
  <div class="course-requests">
    <loading v-if="is_loading" />
    <div v-else>
      <b-row class="course-requests-header">
        <p>{{ $t("g.classrooms/allCourses/course-requests") }}</p>
        <hr class="w-75 border-2" />
      </b-row>
      <course-card-component
        :coursesData="courses"
        @createCourse="createCourse"
        :permission="subPermission"
        @removeCourse="removeCourse"
      />
    </div>
  </div>
</template>

<script>
import loading from "@/components/loading/loading.vue";
import CourseCardComponent from "@/components/classrooms/course/CourseCardComponent/index.vue";
import { BRow } from "bootstrap-vue";

export default {
  name: "AcademicCourses",
  components: {
    BRow,
    loading,
    CourseCardComponent,
  },
  data() {
    return {
      courses: [],
      is_loading: true,
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "courses");
      return this.$store.state.userData.sub_Permission;
    },
  },
  beforeMount() {
    this.getEduYearCourses();
  },
  methods: {
    async getEduYearCourses() {
      try {
        const res = await this.$http.get(`/admin/courses?approved=false`);
        this.courses = res.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
    createCourse() {
      this.getEduYearCourses();
    },
    removeCourse(courseId) {
      this.courses.filter((item) => item.id !== courseId);
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />

<template>
  <validation-observer ref="reportForm">
    <b-form class="teacher-control">
      <b-modal id="bv-modal-teacher-control" centered hide-footer>
        <!-- Start Modal Header -->
        <template #modal-header>
          <div
            class="mb-2 d-flex justify-content-between w-100 align-items-center"
          >
            <b-card-text class="mb-0 head">
              {{ $t("g.addcourse/teacher_control/model_title") }}
            </b-card-text>
            <img
              :src="exitIcon"
              alt="Hide add course"
              @click="$bvModal.hide('bv-modal-teacher-control')"
            />
          </div>
        </template>
        <!-- End Modal Header -->
        <b-row class="w-100 mb-3">
          <h3>{{ $t("selected_teacher") }}</h3>
          <div
            class="w-100 row px-1 mt-1"
            v-for="teacher in selectedTeacher"
            :key="teacher.id"
            style="justify-content: space-between; display: flex"
          >
            <b-col cols="8" class="px-1">
              <b-avatar
                v-if="teacher.user"
                size="2rem"
                :src="
                  $helpers.filterImages(
                    helpers.uiAvatar(teacher.user.first_name),
                    'path',
                    teacher.user.media
                  )
                "
              />
              <span class="ml-1 teacher-row" v-if="teacher.user">
                {{
                  `Mr.${teacher.user.first_name} ${teacher.user.last_name}`
                }}</span
              >
            </b-col>
            <button style="all: unset" @click="deleteItemFromList(teacher)">
              <img
                :src="blueTrashIco"
                class="float-right"
                alt="blue-trash-icon"
              />
            </button>
          </div>
        </b-row>

        <b-row class="w-100">
          <h3>{{ $t("g.not_selected_teacher") }}</h3>
          <div
            class="w-100 row px-1 mt-1"
            v-for="teacher in teachersData"
            :key="teacher.id"
            style="justify-content: space-between; display: flex"
          >
            <b-col cols="8" class="px-1">
              <b-avatar
                v-if="teacher.user"
                size="2rem"
                :src="
                  $helpers.filterImages(
                    helpers.uiAvatar(teacher.user.first_name),
                    'path',
                    teacher.user.media
                  )
                "
              />
              <span class="ml-1 teacher-row" v-if="teacher.user">
                {{
                  `Mr.${teacher.user.first_name} ${teacher.user.last_name}`
                }}</span
              >
            </b-col>
            <button style="all: unset" @click="addTeacher(teacher)">
              <img :src="plusIco" alt="add teacher" class="float-right" />
            </button>
          </div>
        </b-row>

        <div class="w-100 row mt-2 add-teacher d-flex align-items-center">
          <b-col cols="8" class="px-1">
            <img :src="plusIco" alt="plus icon" />
            <span class="link">
              {{ $t("g.addCourse/teacher_control/add_teacher") }}
            </span>
          </b-col>
        </div>

        <!-- Start Form Actions Buttons -->
        <b-row align-h="end" class="report-footer m-0 mt-5">
          <b-button
            variant="outline-primary"
            @click="$bvModal.hide('bv-modal-teacher-control')"
            >{{ $t("g.cancel") }}
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            @click="sentTeachersToMinComponent"
            >{{ $t("g.save") }}
          </b-button>
        </b-row>

        <!-- Overlay component -->
        <overlay-component :isLoading="is_loading" />
        <!-- Overlay component -->
      </b-modal>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BAvatar,
  BModal,
  BButton,
  BCol,
  BForm,
  BFormSelect,
  BFormTextarea,
  BRow,
  BFormFile,
  BFormGroup,
  BCardText,
  BFormInput,
  BFormDatepicker,
  BBadge,
} from "bootstrap-vue";
import helpers from "@/composables/helper";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayComponent from "@/components/shared/OverlayComponent";

export default {
  name: "teacherControl",
  props: ["teachers"],
  components: {
    BModal,
    BAvatar,
    BButton,
    BForm,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormFile,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
    BBadge,
    OverlayComponent,
  },
  data() {
    return {
      teachersData: null,
      selectedTeacher: [],
      exitIcon: require("@/assets/dev/exit_icon.svg"),
      is_loading: false,
      blueTrashIco: require("@/assets/images/icons/trash.svg"),
      plusIco: require("@/assets/images/icons/plus-p.svg"),
    };
  },
  beforeMount() {
    this.getTeachers();
  },
  computed: {
    helpers() {
      return helpers;
    },
  },
  created() {},
  methods: {
    async getTeachers() {
      this.is_loading = true;
      try {
        const res = await this.$http.get("admin/teachers");
        this.teachersData = res.data.data;
        const selectedTeachersIds = this.teachers.map((e) => e.id);

        this.teachersData = this.teachersData.filter(
          (e) => !selectedTeachersIds.includes(e.id)
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
    addTeacher(teacher) {
      this.selectedTeacher.push(teacher);
      this.teachersData = this.teachersData.filter(
        (item) => item.id !== teacher.id
      );
    },
    deleteItemFromList(teacher) {
      this.teachersData.push(teacher);
      this.selectedTeacher = this.selectedTeacher.filter(
        (item) => item.user.id !== teacher.user.id
      );
    },
    sentTeachersToMinComponent() {
      this.$emit("updateTeacher", this.selectedTeacher);
    },
  },
  watch: {
    teachers(newValue) {
      this.selectedTeacher = newValue;
    },
  },
};
</script>
<style lang="scss">
@import "./_teacherControl.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"reportForm"},[_c('b-form',{staticClass:"teacher-control"},[_c('b-modal',{attrs:{"id":"bv-modal-teacher-control","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"mb-2 d-flex justify-content-between w-100 align-items-center"},[_c('b-card-text',{staticClass:"mb-0 head"},[_vm._v(" "+_vm._s(_vm.$t("g.addcourse/teacher_control/model_title"))+" ")]),_c('img',{attrs:{"src":_vm.exitIcon,"alt":"Hide add course"},on:{"click":function($event){return _vm.$bvModal.hide('bv-modal-teacher-control')}}})],1)]},proxy:true}])},[_c('b-row',{staticClass:"w-100 mb-3"},[_c('h3',[_vm._v(_vm._s(_vm.$t("selected_teacher")))]),_vm._l((_vm.selectedTeacher),function(teacher){return _c('div',{key:teacher.id,staticClass:"w-100 row px-1 mt-1",staticStyle:{"justify-content":"space-between","display":"flex"}},[_c('b-col',{staticClass:"px-1",attrs:{"cols":"8"}},[(teacher.user)?_c('b-avatar',{attrs:{"size":"2rem","src":_vm.$helpers.filterImages(
                  _vm.helpers.uiAvatar(teacher.user.first_name),
                  'path',
                  teacher.user.media
                )}}):_vm._e(),(teacher.user)?_c('span',{staticClass:"ml-1 teacher-row"},[_vm._v(" "+_vm._s(("Mr." + (teacher.user.first_name) + " " + (teacher.user.last_name))))]):_vm._e()],1),_c('button',{staticStyle:{"all":"unset"},on:{"click":function($event){return _vm.deleteItemFromList(teacher)}}},[_c('img',{staticClass:"float-right",attrs:{"src":_vm.blueTrashIco,"alt":"blue-trash-icon"}})])],1)})],2),_c('b-row',{staticClass:"w-100"},[_c('h3',[_vm._v(_vm._s(_vm.$t("g.not_selected_teacher")))]),_vm._l((_vm.teachersData),function(teacher){return _c('div',{key:teacher.id,staticClass:"w-100 row px-1 mt-1",staticStyle:{"justify-content":"space-between","display":"flex"}},[_c('b-col',{staticClass:"px-1",attrs:{"cols":"8"}},[(teacher.user)?_c('b-avatar',{attrs:{"size":"2rem","src":_vm.$helpers.filterImages(
                  _vm.helpers.uiAvatar(teacher.user.first_name),
                  'path',
                  teacher.user.media
                )}}):_vm._e(),(teacher.user)?_c('span',{staticClass:"ml-1 teacher-row"},[_vm._v(" "+_vm._s(("Mr." + (teacher.user.first_name) + " " + (teacher.user.last_name))))]):_vm._e()],1),_c('button',{staticStyle:{"all":"unset"},on:{"click":function($event){return _vm.addTeacher(teacher)}}},[_c('img',{staticClass:"float-right",attrs:{"src":_vm.plusIco,"alt":"add teacher"}})])],1)})],2),_c('div',{staticClass:"w-100 row mt-2 add-teacher d-flex align-items-center"},[_c('b-col',{staticClass:"px-1",attrs:{"cols":"8"}},[_c('img',{attrs:{"src":_vm.plusIco,"alt":"plus icon"}}),_c('span',{staticClass:"link"},[_vm._v(" "+_vm._s(_vm.$t("g.addCourse/teacher_control/add_teacher"))+" ")])])],1),_c('b-row',{staticClass:"report-footer m-0 mt-5",attrs:{"align-h":"end"}},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$bvModal.hide('bv-modal-teacher-control')}}},[_vm._v(_vm._s(_vm.$t("g.cancel"))+" ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.sentTeachersToMinComponent}},[_vm._v(_vm._s(_vm.$t("g.save"))+" ")])],1),_c('overlay-component',{attrs:{"isLoading":_vm.is_loading}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal
    :id="`bv-modal-reject-course-${course.id}`"
    :title="$t('g.reject_course')"
    hide-footer
    centered
  >
    <b-row class="justify-content-center">
      <b-col cols="12" class="text-center">
        <h3>{{ $t("g.are_you_sure_you_want_to_reject_this_course") }}</h3>
      </b-col>
      <b-col cols="12" class="text-center mt-2">
        <b-button
          variant="danger"
          class="mr-1"
          @click.stop="rejectCourse(course.id)"
        >
          {{ $t("g.reject") }}
        </b-button>
        <b-button
          variant="primary"
          @click.stop="$bvModal.hide(`bv-modal-reject-course-${course.id}`)"
          >{{ $t("g.cancel") }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BButton, BCol, BModal, BRow } from "bootstrap-vue";

export default {
  name: "RejectCourseModal",
  components: {
    BCol,
    BRow,
    BButton,
    BModal,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  methods: {
    rejectCourse(courseId) {
      this.$http
        .delete(`/admin/courses/${courseId}`)
        .then(() => {
          this.$bvModal.hide(`bv-modal-reject-course-${courseId}`);
          this.$helpers.makeToast(
            "success",
            this.$t("g.rejected_successfully"),
            this.$t("g.course_rejected_successfully")
          );
          this.$emit("courseRejected");
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
  },
};
</script>

<template>
  <b-modal
    :id="`bv-modal-approve-course-${course.id}`"
    :title="$t('g.approve_course')"
    hide-footer
    centered
    lazy
  >
    <validation-observer ref="reportForm">
      <b-form>
        <b-row class="justify-content-center">
          <b-col cols="12" class="text-center">
            <!-- Start Course Price Input -->
            <b-form-group label-for="price" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-input
                  v-model="price"
                  type="number"
                  :placeholder="$t('g.price')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- End Course Price Input -->

            <!-- Start Course Sale Input -->
            <b-form-group label-for="sale" class="mb-2">
              <validation-provider #default="{ errors }" name="sale">
                <b-form-input
                  v-model="sale"
                  type="number"
                  :placeholder="$t('g.sale')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- End Course Sale Input -->
          </b-col>
          <b-col
            cols="12"
            class="text-center mt-2 d-flex justify-content-center"
          >
            <b-button
              :disabled="isLoading"
              variant="primary"
              class="mr-1"
              @click.stop="validation()"
            >
              {{ $t("g.approve") }}
            </b-button>
            <b-button
              variant="danger"
              @click.stop="
                $bvModal.hide(`bv-modal-approve-course-${course.id}`)
              "
              >{{ $t("g.cancel") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BModal,
  BRow,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "ApproveCourseModal",
  components: {
    BCol,
    BRow,
    BForm,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormGroup,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      price: null,
      sale: null,
      isLoading: false,
    };
  },
  methods: {
    validation() {
      this.$refs.reportForm
        .validate()
        .then((success) => success && this.approveCourse());
    },
    approveCourse() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("is_approved", 1);
      formData.append("price", this.price);
      if (this.sale) {
        formData.append("sale", this.sale);
      }

      this.$http
        .post(`/admin/courses/${this.course.id}?_method=PUT`, formData)
        .then(() => {
          this.$helpers.makeToast(
            "success",
            this.$t("g.approved_successfully"),
            this.$t("g.course_approved_successfully")
          );
          this.$emit("removeCourse");
          this.$emit("courseRejected");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$helpers.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

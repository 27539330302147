<template>
  <div class="explore-course-card-component">
    <b-row class="justify-content-center justify-content-md-start">
      <div
        v-if="!is_course_request && !subPackage"
        class="add-course-card mb-2"
        @click="addCourse"
      >
        <div class="content-container">
          <div class="add-circle">
            <img :src="plusIcon" alt="" />
          </div>
          <h3>{{ $t("g.add-course") }}</h3>
        </div>
      </div>

      <!-- v-if="permission" -->
      <b-col v-for="course in coursesData" :key="course.id" cols="auto">
        <b-card
          :title="course.name"
          :class="{
            'card-tooltip': showTooltip === course.id && !is_course_request,
          }"
          @click="permission.show ? openCourse(course.id) : null"
          @mouseover="
            showTooltip = course.id && !is_course_request ? course.id : ''
          "
          @mouseleave="!is_course_request ? (showTooltip = '') : ''"
          :img-src="
            course.image && course.image.length
              ? course.image[0].path
              : courseImagePlaceholder
          "
          :img-alt="course.name"
          img-top
          tag="article"
          class="mb-2 cursor-pointer"
          body-class="d-flex flex-column justify-content-between"
        >
          <b-dropdown
            v-if="showTooltip === course.id && permission.update"
            variant="link"
            class="card-dropdown d-flex justify-content-center"
            right
            no-caret
          >
            <template #button-content>
              <img
                :src="require('@/assets/images/icons/white.svg')"
                :alt="$t('g.more')"
              />
            </template>
            <b-dropdown-item @click="editCourse($event, course.id)">
              {{ $t("g.edit") }}
            </b-dropdown-item>
            <b-dropdown-item @click="deleteCourse($event, course.id)">
              {{ $t("g.delete") }}
            </b-dropdown-item>
          </b-dropdown>

          <b-card-text
            class="price-text"
            v-if="thereIsSale(course.sale, course.price) && course.is_approved"
          >
            {{ course.is_approved ? `${course.sale} ₤` : null }}
            <s> {{ course.price }} ₤ </s>
          </b-card-text>
          <b-card-text class="price-text" v-else>
            {{ course.price }}
          </b-card-text>

          <b-card-text
            v-if="course.edu_years && course.edu_years.length"
            class="edy-text"
          >
            {{ `${$t("grade")} .${course.edu_years[0].name}` }}
          </b-card-text>
          <b-card-text id="text">
            {{
              course.description
                ? course.description.substr(0, 60)
                : $t("g.ThereIsNoDescription")
            }}
          </b-card-text>

          <b-row class="mx-0" v-if="is_course_request">
            <b-button
              v-if="permission.update"
              variant="primary"
              class="mr-1"
              @click.stop="
                $bvModal.show(`bv-modal-approve-course-${course.id}`)
              "
            >
              {{ $t("g.approve") }}
            </b-button>
            <b-button
              v-if="permission.destroy"
              variant="danger"
              @click.stop="$bvModal.show(`bv-modal-reject-course-${course.id}`)"
              >{{ $t("g.cancel") }}
            </b-button>
          </b-row>

          <RejectCourseModal
            v-if="is_course_request"
            :course="course"
            @courseRejected="createCourse"
          />
          <ApproveCourseModal
            v-if="is_course_request"
            :course="course"
            @courseRejected="createCourse"
            @removeCourse="removeCourse"
          />
        </b-card>
      </b-col>
    </b-row>
    <AddCourseModal
      @createCourse="createCourse"
      :is_Edit="toEdit"
      :page_data="modelData"
    />
    <!-- Add course modal -->
  </div>
</template>

<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BButton,
  BCardText,
  BModal,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import AddCourseModal from "../AddCourseModal/index.vue";
import RejectCourseModal from "@/components/classrooms/course/rejectCourseModal/index.vue";
import ApproveCourseModal from "@/components/classrooms/course/ApproveCourseModal/index.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    AddCourseModal,
    ApproveCourseModal,
    OverlayComponent,
    RejectCourseModal,
  },
  props: ["coursesData", "callData", "edu_year", "permission", "subPackage"],
  data() {
    return {
      is_loading: false,
      showTooltip: "",
      plusIcon: require("@/assets/dev/plus.svg"),
      is_course_request: this.$route.name === "courseRequests",
      courseImagePlaceholder: require("@/assets/dev/courseImagePlaceholder.png"),
      toEdit: null,
      modelData: {
        id: null,
        edu_year: null,
        is_summer: null,
      },
    };
  },
  methods: {
    openCourse(courseId) {
      this.$router.push({
        name: "ShowCourse",
        params: {
          id: courseId,
        },
      });
    },
    createCourse() {
      this.$emit("createCourse");
      this.$emit("call-back");
    },
    async editCourse(event, id) {
      this.toEdit = true;
      event.preventDefault();
      event.stopPropagation();

      this.modelData.id = await id;
      this.modelData.edu_year = await this.edu_year;
      this.modelData.is_summer = await !this.edu_year;

      this.$bvModal.show("bv-modal-add-course");
    },
    addCourse() {
      this.modelData.id = null;
      this.toEdit = false;
      this.modelData.edu_year = this.edu_year;
      this.modelData.is_summer = !this.edu_year;
      this.$bvModal.show("bv-modal-add-course");
    },
    removeCourse(courseId) {
      this.$emit("removeCourse", courseId);
    },
    async deleteCourse(event, _id) {
      event.preventDefault();
      event.stopPropagation();
      this.is_loading = true;
      try {
        const request = await this.$http.delete(
          `admin/courses/${_id}?_method=put`
        );
        if (request.status === 200 || request.status === 201) {
          this.$helpers.makeToast(
            "success",
            this.$t("courseDeletedSuccessfully"),
            request.data.message
          );
          this.$emit("call-back");
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
    thereIsSale(sale, price) {
      return parseFloat(sale) > 0 && sale !== price;
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
